body,
html,
#root {
  height: 100%;
  overscroll-behavior-y: contain;
}

.desktopWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 240px;
  min-width: 320px;
  background-color: #0656fb;
  overflow: hidden;
}

.merchItem {
  width: 200px;
  cursor: pointer;
  border: 1px solid black;
}

.merchItemImage {
  width: 200px;
  
}

.iconLabel {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
  text-align: center;
  line-height: 1.2em;
  margin-top: 0px;
/*   min-height: 38px; */
  word-break: break-word;
  margin-bottom: 0;
}

.iconImage {
  width: 70px;
  filter: drop-shadow(0px 3px 4px rgba(0 0 0 / 30%));
  height: 70px;
  object-fit: contain;
}

.startMenuIcon {
  width: 40px;

  height: 40px;
  object-fit: contain;
}

.clock {
  color: black;
  position: absolute;
  right: 12px;
}

.iconWrapperSong {
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;

}

.iconWrapperSong:active {
  opacity: .7;
}

/*
fos-icon {
  cursor: pointer;
  transition: transform .1s;
  background: none;
  box-shadow: none;
  color: white;
  border-radius: 0px;
  font-size: 12px;
  line-height: normal;
  height: fit-content;
}

fos-icon:hover {
  filter: brightness(1.6); 
}

fos-icon:focus {
  width: 63px;
  height: 77px; 
  border: 1px dotted white;  
  filter: brightness(0.8); 
}

fos-icon:active {
  opacity: 0.7;
}
fos-bar {
  height: 41px;
  border-top: 1px solid black;
  padding-left: 0px;
  box-shadow: 0px 4px 12px rgba(0 0 0 / 20%);
  background-color: white;
}
fos-window {
  box-shadow: 0px 6px 14px rgba(0 0 0 / 20%);
  border: 1px solid black;
  border-radius: 4px;
}

fos-window::part(buttons) {
  cursor: pointer;
  box-shadow: 0px 2px 3px rgba(0 0 0 / 10%);
  border: 1px solid black;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: white;
}

fos-window::part(top) {
  height: 30px;
  box-shadow: 0px 2px 12px rgba(0 0 0 / 10%);
  background-color: white;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
fos-window::part(top) div {
  height: 30px;
}

fos-menu::part(window) {
  left: 0px;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: none;
  background-color: white;
  min-width: 280px;
  min-height: 300px;
}

fos-menu::part(title) {
  cursor: pointer;
  border-right: 1px solid black;
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

fos-menu::part(logo) {
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
   -khtml-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none; 
  width: 38px;
}
fos-menu::part(title):active {
  background-color: #0656fb;
}

fos-menu-item {
  cursor: pointer;
  border: 1px solid;
  margin: 2px;
  height: 30px;
}*/

.bottomBar {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid black;
  z-index: 1001;
}


.bottomBarStartButtonLogo {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  width: 38px;
}

.bottomBarStartButton {
   cursor: pointer; 
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: inherit;
}

.bottomBarStartButton:active {
  background-color: #0656fb;
}

.startMenu {
  display: none;
  box-shadow: 0px 6px 14px rgb(0 0 0 / 20%);
  background-color: white;
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  position: absolute;
  bottom: 40px;
  width: 300px;
  height: 400px;
  padding: 20px 20px 20px 20px;
  z-index: 1000;
}

.startMenuButton {
  padding: 10px;
  border: 1px solid;
   cursor: pointer; 
  height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.startMenuButtonWrapper {
  max-height: 370px;
  overflow-y: scroll;
  padding-right: 20px;
}

.startMenuButton:hover {
  color: #0656fb;
}

.startMenuButton:active {
  color: white;
  background-color: #0656fb;
}

.startMenuButtonIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

#bottomStartButton {
  margin-bottom: 0px;
}
.window {
  display: none;
  position: fixed;
  z-index: 9;
  background-color: white;
  text-align: center;
  border: 1px solid black;
  resize: both;
  overflow: overlay;
  min-width: 340px;
  min-height: 240px;
  box-shadow: 0px 6px 14px rgb(0 0 0 / 20%);
  z-index: 999;
  text-align: left;
}

/* .window:focus {
  z-index: 1000;
} */

.windowImage {
  width: 100%;
  /*max-width: 700px;*/
}



.sortable-handler {
  touch-action: none;
}

@media screen and (max-device-width: 700px) {
  .windowImage {
    width: inherit;
    max-width: 400px;
  }
  
}

@media screen and (max-device-width: 768px) {
  #imageViewerResizer {
   visibility: hidden;
  }

}

.loadRing {
  animation: rotateLoad 1s linear infinite;
  visibility: inherit;
  width: 50px;
}

@keyframes rotateLoad {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingImageViewer {
  position: absolute;
    background: white;
    z-index: 1000;
    display: grid;
    place-items: center;
    align-items: center;
  }

.photoItem {
  cursor: pointer;
/*   display: flex;
  align-items: center;
  justify-content: center; */
  color: black;
  height: 100px;
  font-size: 14px;
}

.photoItem:active {
  opacity: 0.7;
}

.photoImage {
  height: 100%;
}

.photoLabel {
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.photoItemWrapper {
  margin: auto;
  width: 70px;
  height: 70px;
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  overflow: hidden;
}
.rhap_container {
  height: 100%;
}
.rhap_repeat-button {
  color: black !important;
}
.rhap_volume-indicator {
  background: black !important;
}
.rhap_volume-button {
  color: black !important;
}

.rhap_main-controls-button {
  color: black !important;
}
.rhap_container {
  box-shadow: none !important;
}
.rhap_progress-indicator {
  background: #0656fb !important;
  box-shadow: none !important;
}
.rhap_progress-filled {
  background-color: #0656fb !important;
}

.photoGalleryWindow {
  padding-top: 20px;
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: space-between;
  align-items: baseline;
  grid-gap: 20px 2px;
  margin-bottom: 20px;
}

.binWindow {
  padding-top: 20px;
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: repeat(2, 100px);
  grid-gap: 10px;
  align-items: baseline;
}

#photoViewerWindow {
   resize: none!important;
   overflow: hidden!important;
}

.photoViewerImageWrapper {
  height: 500px;
  width: 500px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.photoViewerImageWrapperFullscreen {
  height: 80vh;
  width: 80vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
/*   margin-top: -60px; */
}

.photoViewerPhoto {
  /* max-width: 100%; */
}

.desktopIcon {
  /* cursor: pointer; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 12px;
  line-height: normal;
  color: white;
  height: fit-content;
  background: none;
  box-shadow: none;
}

.desktopIcon:active {
  opacity: 0.7;
}

.blinkingAni {
  animation: blinking .2s linear infinite;
}

.openWindowAni {
  animation: openWindow .05s ease-out;
}

.windowHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  /* cursor: move; */
  cursor: default;
  z-index: inherit;
  border: 1px solid;
  background-color: white;
  color: black;
  position: fixed;
  transform: translate(-1px, -1px);
  width: inherit;
  margin-top: -31px;
  min-width: 340px;
}

.windowButtons {
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 60px;
  height: inherit;
  cursor: default;
}

.windowButton {
  /* cursor: pointer; */
  box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
  border: 1px solid;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: white;
  cursor: default;
}

.windowButton:hover {
  color: #0656fb;
}

.windowButton:active {
  color: white;
  background-color: #0656fb;
}

.arrowInButton {
  margin-top: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.imageViewerButton {
  box-shadow: 0px 2px 3px rgb(0 0 0 / 10%);
  border: 1px solid!important;
  width: 32px!important;
  height: 32px!important;
  border-radius: 4px!important;
  background-color: white!important;
  opacity: 1!important;
}

.imageViewerButton:hover {
  color: #0656fb!important;
}

.imageViewerButton:active {
  color: white!important;
  background-color: #0656fb!important;
}

.headerDragArea {
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

/* .windowResizer {
  width: 15px;
  height: 15px;
  background-color: gray;
  position: absolute;
  right: 0px;
  cursor: nw-resize;
  bottom: 0px;
} */


@keyframes blinking {
  50% {
    background-color: black;
    color: white;
  }
}

@keyframes openWindow {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

*:focus {
  outline: none;
}